export interface LocalStorageInterface<T> {
  data: T
  expire: number
}

export const localStorageKeys = {
  siteComments: 'siteComments',
  entryExits: 'entryExits',
  userComments: 'userComments',
}

/**
 * ローカルストレージから値を取得する
 * 有効期限が切れているものについては取得しない
 * @param key - キー
 * @returns
 */
export const getItem = (key: string): unknown | null => {
  const val = localStorage.getItem(key)
  if (!val) {
    return null
  }
  try {
    const v = JSON.parse(val) as LocalStorageInterface<unknown>
    if (!v.expire && v.expire !== -1 && v.expire < Date.now()) {
      localStorage.removeItem(key)
      return null
    }
    return v.data
  } catch (e) {
    return null
  }
}

/**
 * ローカルストレージにデータを保存する
 * 有効期限つきでデータの保存を行う
 * @param key - キー
 * @param value - 値
 * @param expire - 期限(指定しない場合は無制限):単位：秒
 */
export const setItem = (key: string, value: unknown, expire?: number): void => {
  const e = expire === undefined ? -1 : Date.now() + 1000 * expire
  const data = JSON.stringify({ data: value, expire: e })
  localStorage.setItem(key, data)
}

export const removeItem = (key: string): void => {
  localStorage.removeItem(key)
}
